<template>
  <div class="container">
    <!-- <van-nav-bar left-arrow title="编辑资料" right-text="保存"  @click-right="saveUser" @click-left="$router.back()"></van-nav-bar> -->
    <van-nav-bar title="修改密码" left-arrow @click-left="$router.back()" />
    <van-cell-group>
      <van-field
        @blur="checkpassWord"
        label="密码"
        size="large"
        v-model="user.passWord"
        placeholder="请输入密码"
        type="password"
        :error-message="errMes.passWord"
      >
      </van-field>
      <van-field
        @blur="checknewpassWord"
        label="新密码"
        size="large"
        v-model="user.newPassWord"
        placeholder="请输入新密码"
        type="password"
        :error-message="errMes.newPassWord"
      >
      </van-field>
      <van-field
        @blur="checknewpassWord2"
        label="重复新密码"
        size="large"
        v-model="user.newPassWord2"
        placeholder="请重复输入新密码"
        type="password"
        :error-message="errMes.newPassWord2"
      >
      </van-field>
    </van-cell-group>
    <div class="btn-box">
      <van-button type="info" size="large" round block @click="ChangePassWordHandle"
        >确认修改</van-button
      >
    </div>
  </div>
</template>

<script>
//import { changePassWord } from "@/api/user";
import { userChangePassWord } from "@/api/user";
import { mapMutations } from 'vuex'
import { Toast } from "vant";
import store from "@/store";

export default {
  name: "user-changePassWord",
  data() {
    return {
      user: {
        userName: "",
        passWord: "",
        newPassWord: "",
        newPassWord2: "",
      },
      errMes: {
        passWord: "",
        newPassWord: "",
        newPassWord2: "",
      },
    };
  },
  created() {
    if(this.$route.query.pwd){
      this.user.passWord=this.$route.query.pwd;
    }
  },
  methods: {
       ...mapMutations(['clearUser']),
    checkpassWord() {
      if (!this.user.passWord) {
        this.errMes.passWord = "密码不能为空";
        return false;
      }
      this.errMes.passWord = "";
      return true;
    },
    checknewpassWord() {
      if (!this.user.newPassWord) {
        this.errMes.newPassWord = "新密码不能为空";
        return false;
      }
      if (this.user.newPassWord.length<6) {
        this.errMes.newPassWord = "密码长度需要大于等于6位";
        return false;
      }
      this.errMes.newPassWord = "";
      return true;
    },
    checknewpassWord2() {
      if (!this.user.newPassWord2) {
        this.errMes.newPassWord2 = "重复密码不能为空";
        return false;
      }
      if (this.user.newPassWord != this.user.newPassWord2) {
        this.errMes.newPassWord2 = "重复密码不一致";
        return false;
      }

      this.errMes.newPassWord2 = "";
      return true;
    },

    // 保存用户编辑
    async ChangePassWordHandle() {
      if (
        !this.checkpassWord() ||
        !this.checknewpassWord() ||
        !this.checknewpassWord2()
      ) {
        return false;
      }
      this.user.userName=store.state.user.userName;
      try {
        const data = await userChangePassWord(this.user);
        console.log(data);
        console.log(data.data.success)
        if (data.data.success) {
          Toast("修改成功");
          this.clearUser();
          this.$router.push("/login");
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
        Toast("保存失败：" + error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn-box {
  padding: 20px;
}
</style>
